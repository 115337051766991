import "./src/styles.css";
import React from "react";
import Layout from "./src/components/Layout";


export const wrapPageElement = ({ element, props }) => {
  
    // Pokud stránka začíná "/about" nebo "/services"
    if (props.location.pathname.startsWith('/it-outsourcing') 
        || props.location.pathname.startsWith('/digitalizace')
        || props.location.pathname.startsWith('/audit')
        || props.location.pathname.startsWith('/monitoring')
        || props.location.pathname.startsWith('/bezpecnost')
    ) {
      return <Layout {...props}>{element}</Layout>;
    }
  
    // Na ostatních stránkách layout nebude použit
    return element;
  };