import React, { useState } from "react";
import { Link } from "gatsby";
import * as styles from "./layout.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

const sidebarData = [
    { 
        title: "IT Outsourcing", 
        dropdown: [
            { title: "IT outsourcing", path: "/it-outsourcing/" },
            { title: "Digitalizace", path: "/digitalizace/" },
            { title: "IT Audit", path: "/audit/" },
            { title: "Monitoring", path: "/monitoring/" },
        ]
    },
    { 
        title: "Bezpečnost IT", 
        dropdown: [
            { title: "Bezpečnost dat", path: "/bezpecnost/" },
            { title: "Zálohování", path: "/" },
            { title: "Ransomware", path: "/" },
            { title: "Socialhacking", path: "/" },
        ]
    },
    { 
        title: "Servery a Cloudy", 
        dropdown: [
            { title: "Cloud server hosting", path: "/" },
            { title: "Cloudový konfigurátor", path: "/" },
            { title: "Enterprise řešení", path: "/" },
        ]
    },
    { 
        title: "Další IT projekty", 
        dropdown: [
            { title: "A další IT projekty", path: "/dalsi-it-projekty" },
        ]
    },
    { title: "O nás", path: "", noDropdown: true },
    { title: "Kariéra", path: "", noDropdown: true },
    { title: "Kontakt", path: "", noDropdown: true },
];

export default function Layout({ children }) {
    const location = useLocation();
    const [hoverIndex, setHoverIndex] = useState({ parent: null, child: null });

    const [openDropdown, setOpenDropdown] = useState(() => {
        const activeIndex = sidebarData.findIndex(item =>
            item.dropdown?.some(subItem => location.pathname === subItem.path)
        );
        return activeIndex !== -1 ? activeIndex : null;
    });
    

    return (
        <div className={styles.layoutContainer}>
            <div className={styles.mainContent}>{children}</div>
            
            <div className={styles.sidebar}>
                <Link to="/">
                <StaticImage src="../images/logo_revoIT_white_170x35.svg" className={styles.image}/>
                </Link>
                <nav>
                    <ul>
                        {sidebarData.map((item, index) => (
                            <li key={index} className={styles.menuItem}  onMouseEnter={() => setHoverIndex({ parent: index, child: null })} onMouseLeave={() => setHoverIndex({ parent: null, child: null })} >
                                <button
                                    className={`${styles.menuButton} ${location.pathname === item.path || item.dropdown?.some(subItem => location.pathname === subItem.path) ? styles.active : ""}`}
                                    onClick={() =>
                                        setOpenDropdown(openDropdown === index ? null : index)
                                    }
                                >
                                    <Link className={location.pathname === item.path || item.dropdown?.some(subItem => location.pathname === subItem.path) ? styles.active : ""} to={item.path}>{item.title}</Link>
                                </button>

                                {item.dropdown && openDropdown === index && (
                                    <ul className={styles.dropdown}>
                                        {item.dropdown.map((subItem, subIndex) => (
                                            <li key={subIndex} onMouseEnter={() => setHoverIndex({ parent: index, child: subIndex })} onMouseLeave={() => setHoverIndex({ parent: index, child: null })}>
                                                <Link to={subItem.path} className={`${styles.dropdownItem} ${location.pathname === subItem.path ? styles.active : ""}`}>
                                                    {subItem.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
                <motion.div 
                    initial={{ top: 100 }}
                    className={styles.sidebarLine}
                    animate={{ 
                        top: 100 + (hoverIndex.parent !== null 
                            ? hoverIndex.parent * 85 + (hoverIndex.child !== null ? (hoverIndex.child + 1) * 55 : 0) 
                            : 0) 
                    }}
                    transition={{ type: "tween", stiffness: 300, damping: 20 }} 
                />
            </div>
        </div>
    );
}
